import * as React from 'react';

// @mui
import { Container } from "@mui/material";

// hooks
import useSettings from '../../hooks/useSettings';
// components
import DashboardLayout from '../../layouts/dashboard';
import Page from '../../components/Page';

import { PoolDetail } from "../../sections";
import {AddressDisplay} from "../../utils/formatSubstrate";

// ----------------------------------------------------------------------

export default function PoolOperatorDetailPage({poolId, poolLabel=undefined}) {
    const { themeStretch } = useSettings();

    if (poolLabel === undefined) poolLabel=AddressDisplay(poolId);

    return (
        <DashboardLayout>
          <Page title={`Pool Detail: ${poolLabel}`}>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <PoolDetail poolId={poolId} poolLabel={poolLabel}/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
